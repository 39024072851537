"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.distanceDependencies = void 0;

var _dependenciesAbsGenerated = require("./dependenciesAbs.generated.js");

var _dependenciesAddScalarGenerated = require("./dependenciesAddScalar.generated.js");

var _dependenciesDivideScalarGenerated = require("./dependenciesDivideScalar.generated.js");

var _dependenciesMultiplyScalarGenerated = require("./dependenciesMultiplyScalar.generated.js");

var _dependenciesSqrtGenerated = require("./dependenciesSqrt.generated.js");

var _dependenciesSubtractGenerated = require("./dependenciesSubtract.generated.js");

var _dependenciesTypedGenerated = require("./dependenciesTyped.generated.js");

var _dependenciesUnaryMinusGenerated = require("./dependenciesUnaryMinus.generated.js");

var _factoriesAny = require("../../factoriesAny.js");

/**
 * THIS FILE IS AUTO-GENERATED
 * DON'T MAKE CHANGES HERE
 */
var distanceDependencies = {
  absDependencies: _dependenciesAbsGenerated.absDependencies,
  addScalarDependencies: _dependenciesAddScalarGenerated.addScalarDependencies,
  divideScalarDependencies: _dependenciesDivideScalarGenerated.divideScalarDependencies,
  multiplyScalarDependencies: _dependenciesMultiplyScalarGenerated.multiplyScalarDependencies,
  sqrtDependencies: _dependenciesSqrtGenerated.sqrtDependencies,
  subtractDependencies: _dependenciesSubtractGenerated.subtractDependencies,
  typedDependencies: _dependenciesTypedGenerated.typedDependencies,
  unaryMinusDependencies: _dependenciesUnaryMinusGenerated.unaryMinusDependencies,
  createDistance: _factoriesAny.createDistance
};
exports.distanceDependencies = distanceDependencies;