"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.acschDocs = void 0;
var acschDocs = {
  name: 'acsch',
  category: 'Trigonometry',
  syntax: ['acsch(x)'],
  description: 'Calculate the hyperbolic arccosecant of a value, defined as `acsch(x) = ln(1/x + sqrt(1/x^2 + 1))`.',
  examples: ['acsch(0.5)'],
  seealso: ['asech', 'acoth']
};
exports.acschDocs = acschDocs;