"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.onesDependencies = void 0;

var _dependenciesBigNumberClassGenerated = require("./dependenciesBigNumberClass.generated.js");

var _dependenciesMatrixGenerated = require("./dependenciesMatrix.generated.js");

var _dependenciesTypedGenerated = require("./dependenciesTyped.generated.js");

var _factoriesAny = require("../../factoriesAny.js");

/**
 * THIS FILE IS AUTO-GENERATED
 * DON'T MAKE CHANGES HERE
 */
var onesDependencies = {
  BigNumberDependencies: _dependenciesBigNumberClassGenerated.BigNumberDependencies,
  matrixDependencies: _dependenciesMatrixGenerated.matrixDependencies,
  typedDependencies: _dependenciesTypedGenerated.typedDependencies,
  createOnes: _factoriesAny.createOnes
};
exports.onesDependencies = onesDependencies;