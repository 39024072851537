"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.AccessorNodeDependencies = void 0;

var _dependenciesNodeGenerated = require("./dependenciesNode.generated.js");

var _dependenciesSubsetGenerated = require("./dependenciesSubset.generated.js");

var _factoriesAny = require("../../factoriesAny.js");

/**
 * THIS FILE IS AUTO-GENERATED
 * DON'T MAKE CHANGES HERE
 */
var AccessorNodeDependencies = {
  NodeDependencies: _dependenciesNodeGenerated.NodeDependencies,
  subsetDependencies: _dependenciesSubsetGenerated.subsetDependencies,
  createAccessorNode: _factoriesAny.createAccessorNode
};
exports.AccessorNodeDependencies = AccessorNodeDependencies;