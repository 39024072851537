"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.octDependencies = void 0;

var _dependenciesFormatGenerated = require("./dependenciesFormat.generated.js");

var _dependenciesTypedGenerated = require("./dependenciesTyped.generated.js");

var _factoriesAny = require("../../factoriesAny.js");

/**
 * THIS FILE IS AUTO-GENERATED
 * DON'T MAKE CHANGES HERE
 */
var octDependencies = {
  formatDependencies: _dependenciesFormatGenerated.formatDependencies,
  typedDependencies: _dependenciesTypedGenerated.typedDependencies,
  createOct: _factoriesAny.createOct
};
exports.octDependencies = octDependencies;