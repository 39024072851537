"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.equalTextDependencies = void 0;

var _dependenciesCompareTextGenerated = require("./dependenciesCompareText.generated.js");

var _dependenciesIsZeroGenerated = require("./dependenciesIsZero.generated.js");

var _dependenciesTypedGenerated = require("./dependenciesTyped.generated.js");

var _factoriesAny = require("../../factoriesAny.js");

/**
 * THIS FILE IS AUTO-GENERATED
 * DON'T MAKE CHANGES HERE
 */
var equalTextDependencies = {
  compareTextDependencies: _dependenciesCompareTextGenerated.compareTextDependencies,
  isZeroDependencies: _dependenciesIsZeroGenerated.isZeroDependencies,
  typedDependencies: _dependenciesTypedGenerated.typedDependencies,
  createEqualText: _factoriesAny.createEqualText
};
exports.equalTextDependencies = equalTextDependencies;