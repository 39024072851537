"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.RangeDependencies = void 0;

var _factoriesAny = require("../../factoriesAny.js");

/**
 * THIS FILE IS AUTO-GENERATED
 * DON'T MAKE CHANGES HERE
 */
var RangeDependencies = {
  createRangeClass: _factoriesAny.createRangeClass
};
exports.RangeDependencies = RangeDependencies;