"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.tanDocs = void 0;
var tanDocs = {
  name: 'tan',
  category: 'Trigonometry',
  syntax: ['tan(x)'],
  description: 'Compute the tangent of x in radians.',
  examples: ['tan(0.5)', 'sin(0.5) / cos(0.5)', 'tan(pi / 4)', 'tan(45 deg)'],
  seealso: ['atan', 'sin', 'cos']
};
exports.tanDocs = tanDocs;