"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.createAlgorithm13 = void 0;

var _factory = require("../../../utils/factory.js");

var _DimensionError = require("../../../error/DimensionError.js");

var name = 'algorithm13';
var dependencies = ['typed'];
var createAlgorithm13 = /* #__PURE__ */(0, _factory.factory)(name, dependencies, function (_ref) {
  var typed = _ref.typed;

  /**
   * Iterates over DenseMatrix items and invokes the callback function f(Aij..z, Bij..z).
   * Callback function invoked MxN times.
   *
   * C(i,j,...z) = f(Aij..z, Bij..z)
   *
   * @param {Matrix}   a                 The DenseMatrix instance (A)
   * @param {Matrix}   b                 The DenseMatrix instance (B)
   * @param {Function} callback          The f(Aij..z,Bij..z) operation to invoke
   *
   * @return {Matrix}                    DenseMatrix (C)
   *
   * https://github.com/josdejong/mathjs/pull/346#issuecomment-97658658
   */
  return function algorithm13(a, b, callback) {
    // a arrays
    var adata = a._data;
    var asize = a._size;
    var adt = a._datatype; // b arrays

    var bdata = b._data;
    var bsize = b._size;
    var bdt = b._datatype; // c arrays

    var csize = []; // validate dimensions

    if (asize.length !== bsize.length) {
      throw new _DimensionError.DimensionError(asize.length, bsize.length);
    } // validate each one of the dimension sizes


    for (var s = 0; s < asize.length; s++) {
      // must match
      if (asize[s] !== bsize[s]) {
        throw new RangeError('Dimension mismatch. Matrix A (' + asize + ') must match Matrix B (' + bsize + ')');
      } // update dimension in c


      csize[s] = asize[s];
    } // datatype


    var dt; // callback signature to use

    var cf = callback; // process data types

    if (typeof adt === 'string' && adt === bdt) {
      // datatype
      dt = adt; // callback

      cf = typed.find(callback, [dt, dt]);
    } // populate cdata, iterate through dimensions


    var cdata = csize.length > 0 ? _iterate(cf, 0, csize, csize[0], adata, bdata) : []; // c matrix

    return a.createDenseMatrix({
      data: cdata,
      size: csize,
      datatype: dt
    });
  }; // recursive function

  function _iterate(f, level, s, n, av, bv) {
    // initialize array for this level
    var cv = []; // check we reach the last level

    if (level === s.length - 1) {
      // loop arrays in last level
      for (var i = 0; i < n; i++) {
        // invoke callback and store value
        cv[i] = f(av[i], bv[i]);
      }
    } else {
      // iterate current level
      for (var j = 0; j < n; j++) {
        // iterate next level
        cv[j] = _iterate(f, level + 1, s, s[level + 1], av[j], bv[j]);
      }
    }

    return cv;
  }
});
exports.createAlgorithm13 = createAlgorithm13;