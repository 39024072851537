"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.divideScalarDependencies = void 0;

var _dependenciesNumericGenerated = require("./dependenciesNumeric.generated.js");

var _dependenciesTypedGenerated = require("./dependenciesTyped.generated.js");

var _factoriesAny = require("../../factoriesAny.js");

/**
 * THIS FILE IS AUTO-GENERATED
 * DON'T MAKE CHANGES HERE
 */
var divideScalarDependencies = {
  numericDependencies: _dependenciesNumericGenerated.numericDependencies,
  typedDependencies: _dependenciesTypedGenerated.typedDependencies,
  createDivideScalar: _factoriesAny.createDivideScalar
};
exports.divideScalarDependencies = divideScalarDependencies;