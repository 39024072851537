"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ImmutableDenseMatrixDependencies = void 0;

var _dependenciesDenseMatrixClassGenerated = require("./dependenciesDenseMatrixClass.generated.js");

var _dependenciesSmallerGenerated = require("./dependenciesSmaller.generated.js");

var _factoriesAny = require("../../factoriesAny.js");

/**
 * THIS FILE IS AUTO-GENERATED
 * DON'T MAKE CHANGES HERE
 */
var ImmutableDenseMatrixDependencies = {
  DenseMatrixDependencies: _dependenciesDenseMatrixClassGenerated.DenseMatrixDependencies,
  smallerDependencies: _dependenciesSmallerGenerated.smallerDependencies,
  createImmutableDenseMatrixClass: _factoriesAny.createImmutableDenseMatrixClass
};
exports.ImmutableDenseMatrixDependencies = ImmutableDenseMatrixDependencies;