"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.log10Dependencies = void 0;

var _dependenciesComplexClassGenerated = require("./dependenciesComplexClass.generated.js");

var _dependenciesTypedGenerated = require("./dependenciesTyped.generated.js");

var _factoriesAny = require("../../factoriesAny.js");

/**
 * THIS FILE IS AUTO-GENERATED
 * DON'T MAKE CHANGES HERE
 */
var log10Dependencies = {
  ComplexDependencies: _dependenciesComplexClassGenerated.ComplexDependencies,
  typedDependencies: _dependenciesTypedGenerated.typedDependencies,
  createLog10: _factoriesAny.createLog10
};
exports.log10Dependencies = log10Dependencies;