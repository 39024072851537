"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.FibonacciHeapDependencies = void 0;

var _dependenciesLargerGenerated = require("./dependenciesLarger.generated.js");

var _dependenciesSmallerGenerated = require("./dependenciesSmaller.generated.js");

var _factoriesAny = require("../../factoriesAny.js");

/**
 * THIS FILE IS AUTO-GENERATED
 * DON'T MAKE CHANGES HERE
 */
var FibonacciHeapDependencies = {
  largerDependencies: _dependenciesLargerGenerated.largerDependencies,
  smallerDependencies: _dependenciesSmallerGenerated.smallerDependencies,
  createFibonacciHeapClass: _factoriesAny.createFibonacciHeapClass
};
exports.FibonacciHeapDependencies = FibonacciHeapDependencies;