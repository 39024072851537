"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ChainDependencies = void 0;

var _factoriesAny = require("../../factoriesAny.js");

/**
 * THIS FILE IS AUTO-GENERATED
 * DON'T MAKE CHANGES HERE
 */
var ChainDependencies = {
  createChainClass: _factoriesAny.createChainClass
};
exports.ChainDependencies = ChainDependencies;