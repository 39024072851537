"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.nullDependencies = void 0;

var _factoriesAny = require("../../factoriesAny.js");

/**
 * THIS FILE IS AUTO-GENERATED
 * DON'T MAKE CHANGES HERE
 */
var nullDependencies = {
  createNull: _factoriesAny.createNull
};
exports.nullDependencies = nullDependencies;