"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SpaDependencies = void 0;

var _dependenciesFibonacciHeapClassGenerated = require("./dependenciesFibonacciHeapClass.generated.js");

var _dependenciesAddScalarGenerated = require("./dependenciesAddScalar.generated.js");

var _dependenciesEqualScalarGenerated = require("./dependenciesEqualScalar.generated.js");

var _factoriesAny = require("../../factoriesAny.js");

/**
 * THIS FILE IS AUTO-GENERATED
 * DON'T MAKE CHANGES HERE
 */
var SpaDependencies = {
  FibonacciHeapDependencies: _dependenciesFibonacciHeapClassGenerated.FibonacciHeapDependencies,
  addScalarDependencies: _dependenciesAddScalarGenerated.addScalarDependencies,
  equalScalarDependencies: _dependenciesEqualScalarGenerated.equalScalarDependencies,
  createSpaClass: _factoriesAny.createSpaClass
};
exports.SpaDependencies = SpaDependencies;