"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.setUnionDependencies = void 0;

var _dependenciesIndexClassGenerated = require("./dependenciesIndexClass.generated.js");

var _dependenciesConcatGenerated = require("./dependenciesConcat.generated.js");

var _dependenciesSetIntersectGenerated = require("./dependenciesSetIntersect.generated.js");

var _dependenciesSetSymDifferenceGenerated = require("./dependenciesSetSymDifference.generated.js");

var _dependenciesSizeGenerated = require("./dependenciesSize.generated.js");

var _dependenciesSubsetGenerated = require("./dependenciesSubset.generated.js");

var _dependenciesTypedGenerated = require("./dependenciesTyped.generated.js");

var _factoriesAny = require("../../factoriesAny.js");

/**
 * THIS FILE IS AUTO-GENERATED
 * DON'T MAKE CHANGES HERE
 */
var setUnionDependencies = {
  IndexDependencies: _dependenciesIndexClassGenerated.IndexDependencies,
  concatDependencies: _dependenciesConcatGenerated.concatDependencies,
  setIntersectDependencies: _dependenciesSetIntersectGenerated.setIntersectDependencies,
  setSymDifferenceDependencies: _dependenciesSetSymDifferenceGenerated.setSymDifferenceDependencies,
  sizeDependencies: _dependenciesSizeGenerated.sizeDependencies,
  subsetDependencies: _dependenciesSubsetGenerated.subsetDependencies,
  typedDependencies: _dependenciesTypedGenerated.typedDependencies,
  createSetUnion: _factoriesAny.createSetUnion
};
exports.setUnionDependencies = setUnionDependencies;