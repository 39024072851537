"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.fractionDocs = void 0;
var fractionDocs = {
  name: 'fraction',
  category: 'Construction',
  syntax: ['fraction(num)', 'fraction(num,den)'],
  description: 'Create a fraction from a number or from a numerator and denominator.',
  examples: ['fraction(0.125)', 'fraction(1, 3) + fraction(2, 5)'],
  seealso: ['bignumber', 'boolean', 'complex', 'index', 'matrix', 'string', 'unit']
};
exports.fractionDocs = fractionDocs;