"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.rotateDependencies = void 0;

var _dependenciesMultiplyGenerated = require("./dependenciesMultiply.generated.js");

var _dependenciesRotationMatrixGenerated = require("./dependenciesRotationMatrix.generated.js");

var _dependenciesTypedGenerated = require("./dependenciesTyped.generated.js");

var _factoriesAny = require("../../factoriesAny.js");

/**
 * THIS FILE IS AUTO-GENERATED
 * DON'T MAKE CHANGES HERE
 */
var rotateDependencies = {
  multiplyDependencies: _dependenciesMultiplyGenerated.multiplyDependencies,
  rotationMatrixDependencies: _dependenciesRotationMatrixGenerated.rotationMatrixDependencies,
  typedDependencies: _dependenciesTypedGenerated.typedDependencies,
  createRotate: _factoriesAny.createRotate
};
exports.rotateDependencies = rotateDependencies;