import { create, all} from 'mathjs'

const config = {
    number: 'number'
}

const math = create(all, config);


module.exports = math;
