"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.modeDependencies = void 0;

var _dependenciesIsNaNGenerated = require("./dependenciesIsNaN.generated.js");

var _dependenciesIsNumericGenerated = require("./dependenciesIsNumeric.generated.js");

var _dependenciesTypedGenerated = require("./dependenciesTyped.generated.js");

var _factoriesAny = require("../../factoriesAny.js");

/**
 * THIS FILE IS AUTO-GENERATED
 * DON'T MAKE CHANGES HERE
 */
var modeDependencies = {
  isNaNDependencies: _dependenciesIsNaNGenerated.isNaNDependencies,
  isNumericDependencies: _dependenciesIsNumericGenerated.isNumericDependencies,
  typedDependencies: _dependenciesTypedGenerated.typedDependencies,
  createMode: _factoriesAny.createMode
};
exports.modeDependencies = modeDependencies;