"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.prodDependencies = void 0;

var _dependenciesMultiplyScalarGenerated = require("./dependenciesMultiplyScalar.generated.js");

var _dependenciesNumericGenerated = require("./dependenciesNumeric.generated.js");

var _dependenciesTypedGenerated = require("./dependenciesTyped.generated.js");

var _factoriesAny = require("../../factoriesAny.js");

/**
 * THIS FILE IS AUTO-GENERATED
 * DON'T MAKE CHANGES HERE
 */
var prodDependencies = {
  multiplyScalarDependencies: _dependenciesMultiplyScalarGenerated.multiplyScalarDependencies,
  numericDependencies: _dependenciesNumericGenerated.numericDependencies,
  typedDependencies: _dependenciesTypedGenerated.typedDependencies,
  createProd: _factoriesAny.createProd
};
exports.prodDependencies = prodDependencies;