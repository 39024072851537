"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.quantileSeqDependencies = void 0;

var _dependenciesAddGenerated = require("./dependenciesAdd.generated.js");

var _dependenciesCompareGenerated = require("./dependenciesCompare.generated.js");

var _dependenciesMultiplyGenerated = require("./dependenciesMultiply.generated.js");

var _dependenciesPartitionSelectGenerated = require("./dependenciesPartitionSelect.generated.js");

var _dependenciesTypedGenerated = require("./dependenciesTyped.generated.js");

var _factoriesAny = require("../../factoriesAny.js");

/**
 * THIS FILE IS AUTO-GENERATED
 * DON'T MAKE CHANGES HERE
 */
var quantileSeqDependencies = {
  addDependencies: _dependenciesAddGenerated.addDependencies,
  compareDependencies: _dependenciesCompareGenerated.compareDependencies,
  multiplyDependencies: _dependenciesMultiplyGenerated.multiplyDependencies,
  partitionSelectDependencies: _dependenciesPartitionSelectGenerated.partitionSelectDependencies,
  typedDependencies: _dependenciesTypedGenerated.typedDependencies,
  createQuantileSeq: _factoriesAny.createQuantileSeq
};
exports.quantileSeqDependencies = quantileSeqDependencies;