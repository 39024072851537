"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.BigNumberDependencies = void 0;

var _factoriesAny = require("../../factoriesAny.js");

/**
 * THIS FILE IS AUTO-GENERATED
 * DON'T MAKE CHANGES HERE
 */
var BigNumberDependencies = {
  createBigNumberClass: _factoriesAny.createBigNumberClass
};
exports.BigNumberDependencies = BigNumberDependencies;